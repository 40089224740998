<template>
	<div>
		<div class="box">
			<div class="row">
				<div class="mr-auto ml-3 mb-3">
					<e-select
						v-model="residence_selected"
		                :options="residences"
		                :searchable="true"
		                label="lieu_label"
		                :placeholder="$t('monte.choix_lieu')"
	                	:loading="loadingLieu"
					>	
						<template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
				<div v-if="planning.planning_id" class="ml-auto mr-3 mb-3">
					<button class="btn btn-primary" @click="add">{{ $t('monte.create_jument') }}</button>
					<button class="btn btn-secondary ml-2" @click="openModalSaillie">{{ $t('monte.saillie_lot') }}</button>
					<button class="btn btn-secondary ml-2" @click="openModalPrint">{{ $t('monte.imprimer_planning') }}</button>
					<button v-if="planning.planning_horse" class="btn btn-secondary ml-2" @click="openModalSelectHorse">{{ $t('horse.print_infos_horse') }}</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12 d-flex justify-content-between">
					<button class="btn btn-secondary" @click="prev"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></button>
					<div class="d-flex">
						<h3 class="mr-2" style="line-height: 35px;">{{ date_label }}</h3>
						<e-datepicker class="d-inline" v-model="date" id="date" @input="change_date"></e-datepicker>
						<button class="btn btn-sm mx-2" v-bind:class="isToday" @click="change_date_today" >{{$t('global.aujourdhui')}}</button>
					</div>
					<button class="btn btn-secondary" @click="next"><font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
				</div>
			</div>
		</div>
		<template v-if="season.length != 0 && residence_selected.lieu_id && planning.planning_id && !planningLoaded">
			<div class="box p-3" v-for="(horse, index) in planning.planning_horse" :key="index">
				<div class="table-responsive table-calendrier m-0">
					<table class="table table-variante table-monteIA">
						<tr>
							<td rowspan="100%" class="align-middle border-top-0 border-right">
								<b>{{ horse.planninghorse_stallion.horse_nom }}</b> <br>
								<template v-if="horse.nb_dose == 1">
									{{ horse.nb_dose }} {{ $t('monte.dose_preparer') }}
								</template>
								<template v-else-if="horse.nb_dose > 1">
									{{ horse.nb_dose }} {{ $t('monte.doses_preparer') }}
								</template>
							</td>
							<td rowspan="100%" class="align-middle border-top-0 border-right text-md-center">
								{{ horse.planninghorse_schedule.schedule_start }} - {{ horse.planninghorse_schedule.schedule_end }}
							</td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.jument') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.horse_transpondeur') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('gynecologie.mare_status') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.contact') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.phone') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.contrat') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.sanitaire') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.actions') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.commentaire') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.horse_lieustationnement') }}</b></td>
						</tr>
						<template v-for="schedule in planning.planning_schedule">
							<template v-if="schedule.schedule_info[horse.planninghorse_stallion.horse_id] && schedule.schedule_id == horse.planninghorse_schedule.schedule_id">
								<CellCalendarIA
									v-for="(schedule_info, index_2) in schedule.schedule_info[horse.planninghorse_stallion.horse_id]"
									:key="index_2"
									:schedule="schedule.schedule_id"
									:params="schedule_info"
									:season_id="season[0].season_id"
									:edit_schedule.sync="edit_schedule"
									:delete_schedule.sync="delete_schedule"
									:previous_season="previous_season.season_id"
								/>
							</template>
						</template>
					</table>
				</div>
			</div>
		</template>
		<template v-else-if="!planning.planning_id && planningLoaded">
			<LoadingSpinner class="col-12" />
		</template>
		<div class="box" v-else>
			<div class="alert alert-warning mt-5">{{ $t('monte.no_planning') }}</div>
		</div>

		<ModalConfirm 
			ref="modal_delete_schedule" 
			:icon="['fal', 'trash-alt']"
			:text_title="$t('monte.modal_title')"
			:text_question="$t('monte.modal_question')"
			:text_message="$t('modal.general.avertissement_suppression')"
			:text_button_ok="$t('action.supprimer')"
			:text_button_cancel="$t('global.annuler')"
			:callback_validation="deleteSchedule"
			:callback_cancel="closeModal" />

		<ModalPrint 
			ref="modal_print"
			:date="date" 
			:lieu_id="residence_selected.lieu_id"
			componentName="PrintCalendarIA" />

		<ModalAddSaillie
			v-if="season.length != 0 && planning.planning_id"
			ref="modal_saillie"
			:date="date" 
			:planning="planning"
			:season_id="season[0].season_id"
		/>
		<ModalSelectHorse ref="modal_select_horse" />
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
    import PlanningMonte from "@/mixins/PlanningMonte.js"
    import Lieux from "@/mixins/Lieux.js"
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
	import Shutter from '@/mixins/Shutter'
	import Gynecologie from '@/mixins/Gynecologie'
	import MonteShutters from '@/mixins/shutters-manager/Monte'

	export default {
		name: 'CalendarMonteIA',
		mixins: [PlanningMonte, Shutter, MonteShutters, Lieux, Gynecologie],
		data () {
			return {
				date: new Date(),
				date_label: '',
				planning: {},
				season: {},
				previous_season: {},
				edit_schedule: null,
				delete_schedule: null,
				schedulehorse_id: null, // créneau que l'on souhaite supprimer dans la modal

				residences: [],
				residence_selected: {},

				loadingLieu: false,
				planningLoaded: false
			}
		},

		mounted() {
			this.init_component()
		},
		computed :{
			isToday:function() {
				let today = new Date
				if(this.date.getDate()==today.getDate() && this.date.getMonth()==today.getMonth() && this.date.getFullYear()==today.getFullYear()){
					return "btn-primary"
				}
				return "btn-outline-primary"
			}
		},
		methods: {
			async init_component() {
				this.date = new Date();
				this.formatDate()
				this.loadResidence()
			},
			formatDate() {
				this.date_label = this.getTrad("monte.jours."+this.date.getDay())
			},

			async loadResidence() {
				this.loadingLieu = true
				this.season = await this.getCurrentSeason(this.date)

				if(this.season.length === 0) {
					this.loadingLieu = false
					return
				}

				this.previous_season = await this.getPreviousSeasonFromSeason(this.season[0].season_id)

				this.residences = await this.getResidences()
				const residence = await this.getResidencePlanningDefault(this.season[0].season_id, "IA")

				if(residence) {
					this.residence_selected = this.residences.find(lieu => lieu.lieu_id == residence.planning_lieu)
				}
				this.loadingLieu = false
			},

			async loadPlanning() {
				this.planningLoaded = true
				this.planning = {}
				if(this.season.length != 0) {
					const planning = await this.getPlanning(this.season[0].season_id, "IA", this.date, this.residence_selected.lieu_id)
					if(planning.date == this.date) {
						this.planning = planning
						this.planningLoaded = false
					}
					if(!this.planning.planning_horse) {
						return false
					}
					this.planning.planning_horse.forEach(horse => {
						const schedule_info = this.planning.planning_schedule.find(schedule => schedule.schedule_id == horse.planninghorse_schedule.schedule_id && schedule.schedule_info[horse.planninghorse_stallion.horse_id])
						horse.nb_dose = 0
						if(schedule_info) {
							horse.nb_dose = schedule_info.schedule_info[horse.planninghorse_stallion.horse_id].length
						}
					})
				}
			},

			async prev() {
				let date = new Date(this.date.getTime())
				date.setDate(date.getDate() - 1)
				this.date = date
				this.formatDate()
				this.loadPlanning()
			},

			async next() {
				let date = new Date(this.date.getTime())
				date.setDate(date.getDate() + 1)
				this.date = date
				this.formatDate()
				this.loadPlanning()
			},

			add() {
				this.shutterPanel().keepGiven('monte-ia-calendar')

				const shutter = this.monteShutters['monte-ia-ajout']
				shutter.props.planning_id = this.planning.planning_id
				shutter.props.date_preselected = this.date
				shutter.props.season_id = this.season[0].season_id

				shutter.props.schedule_info = null
				shutter.props.schedule_id = null
				shutter.props.horse_id = null

				shutter.onOk = () => {
					this.loadPlanning()
				}

				this.shutterPanel().open(shutter)
			},

			async deleteSchedule() {
				await this.deleteScheduleHorse(this.schedulehorse_id)
				this.closeModal()
				this.loadPlanning()
			},

			closeModal() {
				this.schedulehorse_id = null
				this.$refs.modal_delete_schedule.closeModal()
			},

			openModalPrint() {
				this.$refs.modal_print.openModal()
			},

			openModalSaillie() {
				this.$refs.modal_saillie.openModal()
			},

			change_date() {
				this.formatDate()
				this.loadResidence()
				this.loadPlanning()
			},
			change_date_today() {
				this.date = new Date()
				this.change_date()
			},

			openModalSelectHorse() {
				this.$refs.modal_select_horse.openModal(this.planning)
			},
		},

		watch: {
			edit_schedule (obj) {
				if(!obj) return false

                this.shutterPanel().keepGiven('monte-ia-calendar')

				const shutter = this.monteShutters['monte-ia-ajout']
				shutter.props.schedule_info = obj.schedule_info
				shutter.props.schedule_id = obj.schedule_id
				shutter.props.horse_id = obj.horse_id
				shutter.props.season_id = this.season[0].season_id
				shutter.props.planning_id = this.planning.planning_id

				shutter.onOk = () => {
					this.loadPlanning()
				}

				this.shutterPanel().open(shutter)
			},

			delete_schedule (schedulehorse_id) {
				this.schedulehorse_id = schedulehorse_id.schedulehorse_id
				this.$refs.modal_delete_schedule.openModal()
			},

			residence_selected() {
				this.loadPlanning()
			}
		},

		components: {
			CellCalendarIA : () => import('@/components/Monte/CellCalendarIA'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
			ModalPrint : () => import('@/components/Monte/ModalPrint'),
			ModalAddSaillie: () => import('@/components/Monte/ModalAddSaillie'),
			ModalSelectHorse : () => import('@/components/Monte/ModalSelectHorse'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>